import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from '../components/main/HomePage';
import CarInfoPage from '../components/main/CarInfoPage'; 
import VehiclesPage from '../components/main/VehiclesPage'; 


const Routes = () => {
    return (
        <Router basename="/">
            <Switch>
                <Route exact path="/" component={HomePage} /> {}
                <Route path="/car/:carId" component={CarInfoPage} /> {}
                <Route path="/vehicles" component={VehiclesPage} /> {}
            </Switch>
        </Router>
    );
}

export default Routes;


