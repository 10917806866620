import React from 'react';
import HomePageHeader from '../main/Geral/Navbar/HomePageHeader';
import Hero from '../main/HomePage/Hero/Hero';
import Informations from '../main/HomePage/Informations/Informations';
import TopPicks from '../main/HomePage/topPicks/TopPicks';
import Services from '../../components/main/HomePage/Services-/Services';
import SubscribeNewsletter from '../../components/main/HomePage/Newsletter/SubscribeNewsletter';
import ContactForm from '../../components/main/HomePage/ContactForm/ContactForm';
import Footer from '../main/Geral/Footer/Footer';
import AboutUs from '../main/HomePage/AboutUs/AboutUs';


function HomePage() {
  return (
    <div>
      <HomePageHeader />
      <Hero />
      <Informations />
      <TopPicks />
      <Services />
      <AboutUs />
      <SubscribeNewsletter />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default HomePage;
