import React from 'react';
import './css/style.css';
import Routes from './Routes/routes'; 

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
