import React from 'react';
import { Route } from 'react-router-dom';
import CarInfoHeader from '../main/Geral/Navbar/CarInfoHeader';
import CarInfoFooter from '../main/Geral/Footer/CarInfoFooter';
import CardWrapper from '../../components/main/Cars/CarInfoBox/CardWrapper';
import OtherSuggestions from '../../components/main/Cars/OtherSuggestions/OtherSuggestions';
import LineBetweenSections from '../../components/main/Cars/LineBetweenSections/LineBetweenSections';





function CarInfoPage() {
  <Route path="/car/:carId" component={CarInfoPage} />

  return (
    <div>
      <CarInfoHeader />
      <CardWrapper />
      <LineBetweenSections />
      <OtherSuggestions />
      <CarInfoFooter />
    </div>
  );
}

export default CarInfoPage;