import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './hero.css';
import { useHistory } from 'react-router-dom';

function Hero() {
    const { t } = useTranslation();
    const history = useHistory();

    const handleCarPortfolioClick = () => {
        history.push('/vehicles');
    };

    const yOffset = -150;

    const smoothScrollWithOffset = (e) => {
        const targetId = e.currentTarget.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        e.preventDefault();
    };

    useEffect(() => {
        const heroLinks = document.querySelectorAll('.hero a[href^="#"]');
        heroLinks.forEach(link => {
            link.addEventListener('click', smoothScrollWithOffset);
        });

        return () => {
            heroLinks.forEach(link => {
                link.removeEventListener('click', smoothScrollWithOffset);
            });
        };
    }, []);

    return (
        <div className="hero vh-100 d-flex align-items-center" id='hero'
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 mx-auto text-center">
                        <h1 className="display-4 text-white animate__animated animate__fadeIn">{t('hero.title')}</h1>
                        <br />
                        <p className="text-white my-3 animate__animated animate__fadeIn animate__delay-1s">{t('hero.subtitle')}</p>
                        <br />
                        <br />
                        <a href="#vehicles" className="btn btn-outline-light animate__animated animate__fadeIn animate__delay-2s" onClick={smoothScrollWithOffset}>{t('hero.newArrivalsBtn')}</a>
                        <a onClick={handleCarPortfolioClick} className="btn btn-outline-light animate__animated animate__fadeIn animate__delay-2s" id="carPortfolioButton">{t('hero.carPortfolioBtn')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
