import React from 'react';
import { Route } from 'react-router-dom';
import CarInfoHeader from '../main/Geral/Navbar/CarInfoHeader';
import VehiclesPageFooter from '../main/Geral/Footer/VehiclesPageFooter';
import ListOfCars from '../main/Vehicles/ListOfCars/ListOfCars';


function CarInfoPage() {
  <Route path="/Vehicles" component={CarInfoPage} />

  return (
    <div>
      <CarInfoHeader />
      <ListOfCars />
      <VehiclesPageFooter />
    </div>
  );
}

export default CarInfoPage;